.onlineClassesComponent{
    width: 100%;
    height: 200px;
    content-align: center;
    background-color: white;

    h2{
        content-align: center;
        width: 100%;
        padding: 50px 50px;
    }
}