$theme-colors: (
    "info": tomato,
    "danger": teal
);

$bgcustom-color: lighten($color: #000000, $amount: 25);
$bgcustom-light-color: lighten($color: #000000, $amount: 40);
$bgcustom-lighter-color: lighten($color: #000000, $amount: 60);
$bgcustom-dark-color: lighten($color: #000000, $amount: 10);
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";