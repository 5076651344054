.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: space-around;

    .headerItem {
        cursor: pointer;
        &:hover {
            background-color: black;
        }
      }
}