.facultyPage{
    /* border: 2px black solid; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10px;   

    .facultyPageHeading{
        min-width: 60%;
        align-content: center;
        padding: 2px 20px;
        color: white;
        font: italic bold 26px Georgia, serif;
    }
}